import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Button } from "@components/Button"

export function SupportComm() {
  const data = useStaticQuery(query)
  const { givingImage, planningImage } = data

  return (
    <section className="pt-20 pb-24 sm:pb-16 xl:pb-4">
      <div className="container relative flex justify-center">
        <div className="absolute inset-0 hidden sm:flex">
          <div className="w-1/2 ml-auto">
            <div className="relative left-8 xl:hidden">
              <svg xmlns="http://www.w3.org/2000/svg" width="349.85" height="551.835" viewBox="0 0 349.85 551.835">
                <defs>
                  <clipPath id="sc-k-clip-path">
                    <rect
                      id="Rectangle_913"
                      data-name="Rectangle 913"
                      width="349.85"
                      height="551.835"
                      fill="none"
                      stroke="#fff8f4"
                      strokeWidth="1.5"
                    />
                  </clipPath>
                </defs>
                <g id="Group_5886" data-name="Group 5886" opacity="0.18">
                  <g id="Group_5771" data-name="Group 5771" clipPath="url(#sc-k-clip-path)">
                    <path
                      id="Path_50"
                      data-name="Path 50"
                      d="M.75,269.04,190.117.75H328.892L141.7,264.431,349.33,551.315l-145.971-.031Z"
                      transform="translate(-0.115 -0.115)"
                      fill="none"
                      stroke="#fff8f4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="relative hidden pl-5 pr-8 xl:block xl:-top-4">
              <div style={{ maxWidth: "630px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 599.192 634.335">
                  <defs>
                    <clipPath id="sc-jk-clip-path">
                      <rect
                        id="Rectangle_913"
                        data-name="Rectangle 913"
                        width="333.262"
                        height="525.67"
                        fill="none"
                        stroke="#fff8f4"
                        strokeWidth="1.5"
                      />
                    </clipPath>
                    <clipPath id="sc-jk-clip-path-2">
                      <rect
                        id="Rectangle_914"
                        data-name="Rectangle 914"
                        width="314.502"
                        height="461"
                        fill="none"
                        stroke="#fff8f4"
                        strokeWidth="1.5"
                      />
                    </clipPath>
                  </defs>
                  <g id="Group_6056" data-name="Group 6056" transform="translate(-744.738 -4110.665)">
                    <g id="Group_5886" data-name="Group 5886" transform="translate(744.738 4110.665)" opacity="0.18">
                      <g id="Group_5771" data-name="Group 5771" clipPath="url(#sc-jk-clip-path)">
                        <path
                          id="Path_50"
                          data-name="Path 50"
                          d="M.75,256.32,181.138.75H313.333L135.02,251.928,332.8,525.21l-139.049-.03Z"
                          transform="translate(-0.145 -0.145)"
                          fill="none"
                          stroke="#fff8f4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </g>
                    <g id="Group_5887" data-name="Group 5887" transform="translate(1029.428 4284)" opacity="0.18">
                      <g id="Group_5774" data-name="Group 5774" clipPath="url(#sc-jk-clip-path-2)">
                        <path
                          id="Path_51"
                          data-name="Path 51"
                          d="M314.24.75H220.345V296.325c0,45.676-24.558,74.68-63.439,74.68a60.347,60.347,0,0,1-62.039-58.6c-.037-1.544-.024-3.09.05-4.633v-.178H.75v1.345a156.694,156.694,0,0,0,313.291-7.41Z"
                          transform="translate(-0.244 -0.244)"
                          fill="none"
                          stroke="#fff8f4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="relative xl:w-10/12">
          <div className="support-com__grid">
            <div className="support-com__content">
              <h2 className="font-bold leading-none uppercase font-mongoose text-clamp-40-70 text-blue-light">
                New ways to support our communities.
              </h2>
              <div className="grid-cols-10 xl:grid mt-7">
                <div className="col-span-9 col-start-2">
                  <h3 className="font-bold text-teal-light text-20">Giving back.</h3>
                  <p className="mt-5 text-white text-16">
                    We’re proud to say that we gave our time, skills, and donations to a variety of charitable causes in
                    2022—from harvesting produce for food banks to helping animals find forever families.
                  </p>
                  <Button
                    external={true}
                    className="px-5 py-4 rounded-xl bg-teal-light mt-7"
                    link="/blog/celebrating-giving-tuesday/"
                  >
                    Learn more
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex items-center col-start-2 col-end-4 row-start-2 row-end-3 sm:justify-center xl:justify-start support-com__arrow">
              <div className="relative flex items-end h-full sm:h-auto sm:block top-10 sm:top-0 xl:-top-16">
                <svg xmlns="http://www.w3.org/2000/svg" width="154.5" height="135.652" viewBox="0 0 154.5 135.652">
                  <defs>
                    <clipPath id="sc-arrow-clip-path">
                      <rect
                        id="Rectangle_913"
                        data-name="Rectangle 913"
                        width="86"
                        height="135.652"
                        fill="none"
                        stroke="#fff8f4"
                        strokeWidth="1.5"
                      />
                    </clipPath>
                  </defs>
                  <g id="Group_5978" data-name="Group 5978" transform="translate(-349.5 -4685.174)">
                    <g id="Group_5888" data-name="Group 5888" transform="translate(418 4685.174)" opacity="0.18">
                      <g id="Group_5771" data-name="Group 5771" clipPath="url(#sc-arrow-clip-path)">
                        <path
                          id="Path_50"
                          data-name="Path 50"
                          d="M.75,66.7,47.3.75H81.414L35.4,65.568,86.438,136.09l-35.883-.008Z"
                          transform="translate(-0.594 -0.594)"
                          fill="none"
                          stroke="#fff8f4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </g>
                    <g id="Group_5889" data-name="Group 5889" transform="translate(349.5 4685.174)" opacity="0.18">
                      <g id="Group_5771-2" data-name="Group 5771" clipPath="url(#sc-arrow-clip-path)">
                        <path
                          id="Path_50-2"
                          data-name="Path 50"
                          d="M.75,66.7,47.3.75H81.414L35.4,65.568,86.438,136.09l-35.883-.008Z"
                          transform="translate(-0.594 -0.594)"
                          fill="none"
                          stroke="#fff8f4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div className="support-com__giving sm:py-11 xl:py-0">
              <div>
                <GatsbyImage objectFit="contain" image={getImage(givingImage)} alt="Giving" />
              </div>
            </div>
            <div className="flex items-center support-com__planning">
              <div className="relative sm:-left-8 xl:-top-16">
                <GatsbyImage objectFit="contain" image={getImage(planningImage)} alt="Planning" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const query = graphql`
  query {
    givingImage: file(relativePath: { eq: "year-in-review/giving.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    planningImage: file(relativePath: { eq: "year-in-review/planning.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
