import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CertWBENC from "@svg/certificates/wbenc.svg"
import CertWomenOwned from "@svg/certificates/women-owned.svg"
import CertSmallBusiness from "@svg/certificates/small-business.svg"
import { Button } from "@components/Button"

export function Opportunities() {
  const data = useStaticQuery(query)
  const { wobGroupImage } = data
  return (
    <section>
      <div className="container">
        <div className="relative pt-16 bg-white-dark">
          <div className="flex flex-col text-center">
            <div className="w-full mx-auto sm:w-10/12 lg:w-7/12">
              <h2 className="px-6 font-bold leading-none uppercase sm:px-0 font-mongoose text-clamp-40-70">
                New opportunities as part of the women-owned business community.
              </h2>
            </div>
            <div className="w-full mx-auto mt-10 sm:w-10/12 lg:w-8/12">
              <p className="px-6 lg:px-12 text-16">
                One of our proudest achievements of 2022 was being certified by the Women’s Business Enterprise National
                Council (WBENC) as a Women’s Business Enterprise. As a women-owned agency with a female CEO and women in
                more than half of our leadership positions, we’re thrilled to be recognized by WBENC—and excited about
                the new chances ahead to support our clients’ supplier diversity goals.{" "}
              </p>
            </div>
            <div className="mt-10">
              <div className="inline-flex flex-col-reverse flex-wrap gap-12 lg:flex-row">
                <div className="flex gap-5 mx-auto lg:mx-0 lg:gap-12">
                  <div className="w-20 sm:w-28">
                    <a href="https://www.wbenc.org/" target="_blank" rel="noreferrer">
                      <CertWomenOwned />
                    </a>
                  </div>
                  <div className="w-20 sm:w-30">
                    <a href="https://www.wbenc.org/" target="_blank" rel="noreferrer">
                      <CertWBENC />
                    </a>
                  </div>
                  <div className="w-20 sm:w-30">
                    <CertSmallBusiness />
                  </div>
                </div>
                <div className="flex items-center justify-center w-full lg:w-auto">
                  <Button
                    external={true}
                    className="px-5 py-4 rounded-xl bg-teal-light"
                    link="/blog/jk-design-recognized-as-a-wbenc-certified-womens-business-enterprise/"
                  >
                    Learn more
                  </Button>
                </div>
              </div>
            </div>
            <div className="w-10/12 mx-auto mt-12 lg:w-7/12">
              <GatsbyImage
                objectFit="contain"
                loading="eager"
                image={getImage(wobGroupImage)}
                alt="Women owned business group shot."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const query = graphql`
  query {
    wobGroupImage: file(relativePath: { eq: "year-in-review/wob-group.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
