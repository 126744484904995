import React from "react"

export function Hero() {
  return (
    <section>
      <div className="container relative">
        <div className="absolute inset-0">
          <div className="container h-full">
            <div className="relative w-full h-full sm:flex sm:w-5/6 top-20 lg:top-0 lg:pl-20 lg:w-5/12 lg:-right-9">
              <svg
                className="relative -left-10 lg:left-0 lg:hidden w-110 sm:w-full"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                viewBox="0 0 506.547 463.934"
              >
                <defs>
                  <clipPath id="jk-hero-clip-path">
                    <rect
                      id="Rectangle_914"
                      data-name="Rectangle 914"
                      width="316.504"
                      height="463.934"
                      fill="none"
                      stroke="#fff8f4"
                      strokeWidth="1.5"
                    />
                  </clipPath>
                  <clipPath id="jk-hero-clip-path-2">
                    <rect
                      id="Rectangle_913"
                      data-name="Rectangle 913"
                      width="221.815"
                      height="349.879"
                      fill="none"
                      stroke="#fff8f4"
                      strokeWidth="1.5"
                    />
                  </clipPath>
                </defs>
                <g id="Group_6059" data-name="Group 6059" transform="translate(-52 -79)">
                  <g id="Group_5775" data-name="Group 5775" transform="translate(52 79)" opacity="0.18">
                    <g id="Group_5774" data-name="Group 5774" clipPath="url(#jk-hero-clip-path)">
                      <path
                        id="Path_51"
                        data-name="Path 51"
                        d="M316.235.75H221.743V298.206c0,45.967-24.714,75.155-63.843,75.155a60.731,60.731,0,0,1-62.434-58.975c-.037-1.554-.024-3.11.05-4.663v-.179H.75V310.9a157.691,157.691,0,0,0,315.285-7.457Z"
                        transform="translate(-0.24 -0.24)"
                        fill="none"
                        stroke="#fff8f4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </g>
                  <g id="Group_5773" data-name="Group 5773" transform="translate(336.732 155.831)" opacity="0.18">
                    <g
                      id="Group_5771"
                      data-name="Group 5771"
                      transform="translate(0)"
                      clipPath="url(#jk-hero-clip-path-2)"
                    >
                      <path
                        id="Path_50"
                        data-name="Path 50"
                        d="M.75,170.854,120.814.75H208.8L90.118,167.931,221.759,349.823l-92.549-.02Z"
                        transform="translate(-0.347 -0.347)"
                        fill="none"
                        stroke="#fff8f4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </g>
                </g>
              </svg>

              <svg
                className="hidden lg:block"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                viewBox="0 0 530.447 674.913"
              >
                <defs>
                  <clipPath id="jk-hero-clip-path">
                    <rect
                      id="Rectangle_914"
                      data-name="Rectangle 914"
                      width="394.233"
                      height="577.869"
                      fill="none"
                      stroke="#fff8f4"
                      strokeWidth="1.5"
                    />
                  </clipPath>
                  <clipPath id="jk-hero-clip-path-2">
                    <rect
                      id="Rectangle_913"
                      data-name="Rectangle 913"
                      width="250.429"
                      height="395.014"
                      fill="none"
                      stroke="#fff8f4"
                      strokeWidth="1.5"
                    />
                  </clipPath>
                </defs>
                <g id="Group_6057" data-name="Group 6057" transform="translate(-106.157 13.156)">
                  <g id="Group_5775" data-name="Group 5775" transform="translate(106.157 -13.156)" opacity="0.18">
                    <g id="Group_5774" data-name="Group 5774" clipPath="url(#jk-hero-clip-path)">
                      <path
                        id="Path_51"
                        data-name="Path 51"
                        d="M393.713.75h-117.7V371.257c0,57.256-30.784,93.612-79.522,93.612a75.645,75.645,0,0,1-77.767-73.458c-.047-1.936-.03-3.874.063-5.808v-.223H.75v1.686C4.136,495.491,94.778,580.641,203.2,577.252c107.261-3.352,191.986-92.173,190.262-199.475Z"
                        transform="translate(-0.115 -0.115)"
                        fill="none"
                        stroke="#fff8f4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </g>
                  <g id="Group_5773" data-name="Group 5773" transform="translate(386.175 266.743)" opacity="0.18">
                    <g id="Group_5771" data-name="Group 5771" clipPath="url(#jk-hero-clip-path-2)">
                      <path
                        id="Path_50"
                        data-name="Path 50"
                        d="M.75,192.8,136.3.75H235.64L101.647,189.5,250.27,394.854l-104.488-.022Z"
                        transform="translate(-0.295 -0.295)"
                        fill="none"
                        stroke="#fff8f4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div className="relative grid-cols-6 gap-4 pb-16 sm:grid lg:grid-cols-12 pt-clamp-32-56">
          <div className="items-center hidden col-span-1 sm:flex">
            <div className="text-white" style={{ writingMode: "vertical-lr", transform: "scale(-1, -1)" }}>
              SCROLL TO EXPLORE
            </div>
          </div>
          <div className="col-span-4 lg:col-span-10">
            <div className="text-center">
              <h2 className="text-purple-light font-body text-14">2022 IN REVIEW</h2>
              <div>
                <h1 className="relative mt-8 uppercase text-blue-light font-mongoose text-clamp-55-140">
                  A year of new people, partners &amp; powerful{" "}
                  <span className="lg:relative">
                    <span className="relative z-1">ideas.</span>
                    <span className="absolute bottom-0 items-end hidden h-full sm:flex -right-20 lg:-right-16 lg:-bottom-7 lg:block">
                      <svg xmlns="http://www.w3.org/2000/svg" width="144" height="135.652" viewBox="0 0 144 135.652">
                        <defs>
                          <clipPath id="jk-kk-clip-path">
                            <rect
                              id="Rectangle_913"
                              data-name="Rectangle 913"
                              width="86"
                              height="135.652"
                              fill="none"
                              stroke="#fff8f4"
                              strokeWidth="1"
                            />
                          </clipPath>
                        </defs>
                        <g id="Group_6058" data-name="Group 6058" transform="translate(-986.5 -434.25)">
                          <g id="Group_5776" data-name="Group 5776" transform="translate(986.5 434.25)" opacity="0.18">
                            <g id="Group_5771" data-name="Group 5771" clipPath="url(#jk-kk-clip-path)">
                              <path
                                id="Path_50"
                                data-name="Path 50"
                                d="M.75,66.7,47.3.75H81.414L35.4,65.568,86.438,136.09l-35.883-.008Z"
                                transform="translate(-0.594 -0.594)"
                                fill="none"
                                stroke="#fff8f4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </g>
                          <g id="Group_5777" data-name="Group 5777" transform="translate(1044.5 434.25)" opacity="0.18">
                            <g id="Group_5771-2" data-name="Group 5771" clipPath="url(#jk-kk-clip-path)">
                              <path
                                id="Path_50-2"
                                data-name="Path 50"
                                d="M.75,66.7,47.3.75H81.414L35.4,65.568,86.438,136.09l-35.883-.008Z"
                                transform="translate(-0.594 -0.594)"
                                fill="none"
                                stroke="#fff8f4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </span>
                </h1>
              </div>

              <p className="mt-4 text-white font-semi">With plenty of twists, turns &amp; triumphs along the way.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
