import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Carousel, SwiperWithPagination } from "@components/Carousel"
import NewPartnersDesktop from "@svg/year-in-review/new-partners-desktop.svg"
import NewPartnersTablet from "@svg/year-in-review/new-partners-tablet.svg"
import NewPartnersMobile from "@svg/year-in-review/new-partners-mobile.svg"
import NewThingsArrowLeft from "@svg/year-in-review/new-things-arrow-left.svg"
import NewThingsJ from "@svg/year-in-review/new-things-j.svg"

function NewPartners() {
  return (
    <div className="w-full mx-auto xl:w-10/12">
      <div className="text-center">
        <h2 className="px-6 font-bold leading-none uppercase sm:px-0 text-teal-light font-mongoose text-clamp-40-70">
          New Partners.
        </h2>
        1
      </div>
      <div className="px-8 mt-14">
        <div className="hidden xl:block">
          <NewPartnersDesktop />
        </div>
        <div className="hidden sm:block xl:hidden">
          <NewPartnersTablet />
        </div>
        <div className="sm:hidden">
          <NewPartnersMobile />
        </div>
      </div>
    </div>
  )
}

function FaceCard({ children, name }) {
  return (
    <div className="xl:px-4">
      <div className="relative ">
        <div className="absolute inset-0 items-center justify-center hidden opacity-0 sm:flex z-1 hover:opacity-100">
          <div className="absolute inset-0 opacity-75 bg-gray-dark"></div>
          <span className="relative px-4 leading-none text-center text-purple-light text-16">{name}</span>
        </div>
        {children}
        <div className="relative sm:hidden">
          <div className="absolute top-0 w-full">
            <span className="block pt-2 text-center text-purple-light text-14">{name}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function NewFaces() {
  const data = useStaticQuery(query)

  const {
    chloeImage,
    christineImage,
    davidImage,
    giannaImage,
    haleyImage,
    jennImage,
    jodiImage,
    jordanImage,
    romitImage,
    zachImage,
  } = data

  return (
    <div className="relative">
      <div className="absolute inset-0 hidden grid-cols-6 sm:grid xl:grid-cols-12">
        <div className="flex items-end col-span-2 xl:col-span-3">
          <div className="relative w-full -bottom-4">
            <NewThingsArrowLeft />
          </div>
        </div>
        <div className="flex items-end col-span-3 col-start-4 xl:col-start-10">
          <div className="relative w-full -bottom-4">
            <NewThingsJ />
          </div>
        </div>
      </div>

      <div className="w-full mx-auto xl:w-10/12">
        <div className="text-center">
          <h2 className="px-6 font-bold leading-none uppercase sm:px-0 text-teal-light font-mongoose text-clamp-40-70">
            New Faces.
          </h2>
          1
        </div>
        <div className="grid grid-cols-3 gap-x-4 gap-y-14 sm:grid-cols-4 xl:grid-cols-5 sm:gap-x-8 sm:gap-y-8 xl:gap-x-4 xl:gap-y-11 mt-14">
          <FaceCard name="Chloe Orr">
            <GatsbyImage objectFit="contain" image={getImage(chloeImage)} alt="Chloe Orr" />
          </FaceCard>
          <FaceCard name="Christine Potkul">
            <GatsbyImage objectFit="contain" image={getImage(christineImage)} alt="Christine Potkul" />
          </FaceCard>
          <FaceCard name="David Herrera">
            <GatsbyImage objectFit="contain" image={getImage(davidImage)} alt="David Herrera" />
          </FaceCard>
          <FaceCard name="Gianna Briglia">
            <GatsbyImage objectFit="contain" image={getImage(giannaImage)} alt="Gianna Briglia" />
          </FaceCard>
          <FaceCard name="Haley Bissegger">
            <GatsbyImage objectFit="contain" image={getImage(haleyImage)} alt="Haley Bissegger" />
          </FaceCard>
          <FaceCard name="Jenn Taggart">
            <GatsbyImage objectFit="contain" image={getImage(jennImage)} alt="Jenn Taggart" />
          </FaceCard>
          <FaceCard name="Jodi Seiferheld">
            <GatsbyImage objectFit="contain" image={getImage(jodiImage)} alt="Jodi Seiferheld" />
          </FaceCard>
          <FaceCard name="Jordan Boudreau">
            <GatsbyImage objectFit="contain" image={getImage(jordanImage)} alt="Jordan Boudreau" />
          </FaceCard>
          <FaceCard name="Romit Sarkar">
            <GatsbyImage objectFit="contain" image={getImage(romitImage)} alt="Romit Sarkar" />
          </FaceCard>
          <FaceCard name="Zach Bard">
            <GatsbyImage objectFit="contain" image={getImage(zachImage)} alt="Zach Bard" />
          </FaceCard>
        </div>
      </div>
    </div>
  )
}

function FriendCard({ children, name }) {
  return (
    <div className="relative ">
      <div className="absolute inset-0 items-center justify-center hidden opacity-0 sm:flex z-1 hover:opacity-100">
        <div className="absolute inset-0 opacity-75 bg-gray-dark"></div>
        <span className="relative px-4 leading-none text-center text-purple-light text-16">{name}</span>
      </div>
      {children}
      <div className="relative sm:hidden">
        <div className="absolute top-0 w-full">
          <span className="block pt-2 text-center text-purple-light text-14">{name}</span>
        </div>
      </div>
    </div>
  )
}

function FurryFriends() {
  const data = useStaticQuery(query)

  const {
    animalOneImage,
    animalTwoImage,
    animalThreeImage,
    animalFourImage,
    animalFiveImage,
    animalSixImage,
    animalSevenImage,
    animalEightImage,
  } = data
  return (
    <div className="relative">
      <div className="absolute inset-0 hidden grid-cols-6 sm:grid xl:grid-cols-12">
        <div className="flex items-end col-span-2 xl:col-span-3">
          <div className="relative w-full -bottom-4">
            <NewThingsArrowLeft />
          </div>
        </div>
        <div className="flex items-end col-span-3 col-start-4 xl:col-start-10">
          <div className="relative w-full -bottom-4">
            <NewThingsJ />
          </div>
        </div>
      </div>
      <div className="w-full mx-auto xl:w-8/12">
        <div className="text-center">
          <h2 className="px-6 font-bold leading-none uppercase sm:px-0 text-teal-light font-mongoose text-clamp-40-70">
            New furry friends.
          </h2>
          1
        </div>
        <div className="grid grid-cols-3 gap-x-4 gap-y-14 sm:grid-cols-4 sm:gap-x-8 sm:gap-y-8 xl:gap-x-11 xl:gap-y-15 mt-14">
          <FriendCard name="Angel & Sun">
            <GatsbyImage objectFit="contain" image={getImage(animalOneImage)} alt="Angel & Sun" />
          </FriendCard>
          <FriendCard name="Louie">
            <GatsbyImage objectFit="contain" image={getImage(animalTwoImage)} alt="Louie" />
          </FriendCard>
          <FriendCard name="Gazpacho & Marzipan">
            <GatsbyImage objectFit="contain" image={getImage(animalThreeImage)} alt="Gazpacho & Marzipan" />
          </FriendCard>
          <FriendCard name="Gus">
            <GatsbyImage objectFit="contain" image={getImage(animalFourImage)} alt="Gus" />
          </FriendCard>
          <FriendCard name="Prudence">
            <GatsbyImage objectFit="contain" image={getImage(animalFiveImage)} alt="Prudence" />
          </FriendCard>
          <FriendCard name="Blondie">
            <GatsbyImage objectFit="contain" image={getImage(animalSixImage)} alt="Blondie" />
          </FriendCard>
          <FriendCard name="Millie">
            <GatsbyImage objectFit="contain" image={getImage(animalSevenImage)} alt="Millie" />
          </FriendCard>
          <FriendCard name="Charles, Paneer & Linguini">
            <GatsbyImage objectFit="contain" image={getImage(animalEightImage)} alt="Charles, Paneer & Linguini" />
          </FriendCard>
        </div>
      </div>
    </div>
  )
}

export function NewThings() {
  return (
    <section className="pb-16 mt-20">
      <div className="container">
        <div className="overflow-hidden">
          <SwiperWithPagination>
            <Carousel.Slide>
              <NewPartners />
            </Carousel.Slide>
            <Carousel.Slide>
              <NewFaces />
            </Carousel.Slide>
            <Carousel.Slide>
              <FurryFriends />
            </Carousel.Slide>
          </SwiperWithPagination>
        </div>
      </div>
    </section>
  )
}

const query = graphql`
  query {
    chloeImage: file(relativePath: { eq: "year-in-review/team/chloe.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    christineImage: file(relativePath: { eq: "year-in-review/team/christine.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    davidImage: file(relativePath: { eq: "year-in-review/team/david.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    giannaImage: file(relativePath: { eq: "year-in-review/team/gianna.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    haleyImage: file(relativePath: { eq: "year-in-review/team/haley.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    jennImage: file(relativePath: { eq: "year-in-review/team/jenn.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    jodiImage: file(relativePath: { eq: "year-in-review/team/jodi.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    jordanImage: file(relativePath: { eq: "year-in-review/team/jordan.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    romitImage: file(relativePath: { eq: "year-in-review/team/romit.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    zachImage: file(relativePath: { eq: "year-in-review/team/zach.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    animalOneImage: file(relativePath: { eq: "year-in-review/animals/animal-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    animalTwoImage: file(relativePath: { eq: "year-in-review/animals/animal-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    animalThreeImage: file(relativePath: { eq: "year-in-review/animals/animal-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    animalFourImage: file(relativePath: { eq: "year-in-review/animals/animal-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    animalFiveImage: file(relativePath: { eq: "year-in-review/animals/animal-5.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    animalSixImage: file(relativePath: { eq: "year-in-review/animals/animal-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    animalSevenImage: file(relativePath: { eq: "year-in-review/animals/animal-7.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    animalEightImage: file(relativePath: { eq: "year-in-review/animals/animal-8.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
