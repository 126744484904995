import React from "react"

export function Achieve() {
  return (
    <section>
      <div className="container text-center">
        <div className="bg-purple-light pb-clamp-14-25 pt-clamp-5-16">
          <div className="px-4 mx-auto py-9 sm:px-0 sm:w-4/6 lg:w-full">
            <h2 className="relative inline-block font-bold leading-10 uppercase font-mongoose text-clamp-40-70">
              What new successes can we help you achieve this year?
              <div className="absolute inset-0 flex items-center pointer-events-none">
                <svg className="ml-auto" width="126.5" height="111.068" viewBox="0 0 126.5 111.068">
                  <defs>
                    <clipPath id="arrow-clip-path">
                      <rect
                        id="Rectangle_913"
                        data-name="Rectangle 913"
                        width="70.414"
                        height="111.068"
                        fill="none"
                        stroke="#191919"
                        strokeWidth="1.5"
                      />
                    </clipPath>
                  </defs>
                  <g id="Group_6010" data-name="Group 6010" transform="translate(-1131.5 -4967)">
                    <g id="Group_5897" data-name="Group 5897" transform="translate(1131.5 4967)" opacity="0.18">
                      <g id="Group_5771" data-name="Group 5771" clipPath="url(#arrow-clip-path)">
                        <path
                          id="Path_50"
                          data-name="Path 50"
                          d="M70.909,54.749,32.795.75H4.864L42.539,53.821.75,111.562l29.379-.006Z"
                          transform="translate(-0.622 -0.622)"
                          fill="none"
                          stroke="#191919"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </g>
                    <g id="Group_5898" data-name="Group 5898" transform="translate(1187.586 4967)" opacity="0.18">
                      <g id="Group_5771-2" data-name="Group 5771" clipPath="url(#arrow-clip-path)">
                        <path
                          id="Path_50-2"
                          data-name="Path 50"
                          d="M70.909,54.749,32.795.75H4.864L42.539,53.821.75,111.562l29.379-.006Z"
                          transform="translate(-0.622 -0.622)"
                          fill="none"
                          stroke="#191919"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </h2>
          </div>
          <div className="w-10/12 mx-auto lg:w-1/2">
            <p className="px-4 text-16">
              Whether you want to refresh your story, launch a new product, or attract top talent to your team, JK is
              ready to help. Let’s talk about how we can make great things happen for you in 2023.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
