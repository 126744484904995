import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { Button } from "@components/Button"

function B2bBranding() {
  const data = useStaticQuery(query)
  const { b2bBrandingImage } = data

  return (
    <div className="grid-cols-10 xl:grid">
      <div className="relative col-span-4">
        <div className="relative sm:breakout xl:mx-0">
          <div
            className="absolute inset-0 hidden bg-teal-light xl:block"
            style={{ clipPath: "polygon(10% 0, 100% 10%, 90% 100%, 0 90%)" }}
          ></div>
          <div className="absolute inset-0 hidden sm:block xl:hidden">
            <div
              className="w-10/12 h-full ml-auto extend bg-teal-light after:bg-teal-light"
              style={{ clipPath: "polygon(5% 0, 100% 10%, 100% 100%, 0 90%)" }}
            ></div>
          </div>
          <div className="absolute inset-0 sm:hidden">
            <div
              className="h-full extend bg-teal-light before:bg-teal-light after:bg-teal-light"
              style={{
                clipPath: "polygon(5% 0, 100% 10%, 100% 100%, 0 90%)",
                width: "150%",
                marginLeft: "-25%",
              }}
            ></div>
          </div>
          <div className="relative flex items-center min-h-550px">
            <div className="grid-cols-2 gap-2 sm:grid xl:gap-0 xl:block">
              <div className="flex flex-col xl:block">
                <div className="pr-12 sm:ml-auto xl:w-3/4 xl:mx-auto xl:pr-0">
                  <h3 className="font-bold leading-8 uppercase font-mongoose text-clamp-35-50">B2B branding.</h3>
                </div>
                <div className="block grid-cols-8 mt-8 xl:grid">
                  <div className="col-span-7 pr-5 xl:pr-0">
                    <GatsbyImage
                      objectFit="contain"
                      loading="eager"
                      image={getImage(b2bBrandingImage)}
                      alt="employer branding"
                    />
                  </div>
                </div>
              </div>
              <div className="block mx-auto mt-8 sm:hidden xl:block xl:w-3/4 xl:max-w-full">
                <p className="px-4 text-16 sm:pl-0 sm:pr-7 xl:pr-0">
                  B2B customers are people too! That’s why emotion, authenticity, and storytelling are all critical for
                  engaging them. Check out some examples of our thinking on how to create—or revitalize—a B2B brand that
                  connects.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 hidden sm:block xl:hidden">
          <div className="container flex items-center h-full">
            <div>
              <p className="w-1/2 pl-4 ml-auto text-16 pr-7">
                B2B customers are people too! That’s why emotion, authenticity, and storytelling are all critical for
                engaging them. Check out some examples of our thinking on how to create—or revitalize—a B2B brand that
                connects.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-6 mt-6 xl:mt-0">
        <div className="grid sm:grid-cols-2 sm:gap-x-11 xl:gap-x-0 gap-y-8">
          <div>
            <div className="w-10/12 mx-auto sm:ml-auto sm:mr-0 xl:w-4/5">
              <h4 className="font-bold text-20">What to do when your brand needs a boost.</h4>
              <p className="mt-3 text-14">
                A free guide to help you decide if your B2B brand needs a total overhaul—or just an infusion of fresh
                ideas.
              </p>
              <Button external={true} link="/b2b-branding-guide/" className="mt-4 text-blue-light">
                Learn more
              </Button>
            </div>
          </div>
          <div>
            <div className="w-10/12 mx-auto xl:ml-auto xl:w-4/5">
              <h4 className="font-bold text-20">B2B storytelling 101.</h4>
              <p className="mt-3 text-14">Four ways to use brand storytelling to bolster your B2B marketing efforts.</p>
              <Button external={true} link="/blog/b2b-storytelling-101/" className="mt-4 text-blue-light">
                Learn more
              </Button>
            </div>
          </div>
          <div>
            <div className="w-10/12 mx-auto sm:ml-auto sm:mr-0 xl:w-4/5">
              <h4 className="font-bold text-20">Attract and convert more B2B customers with the right brand story.</h4>
              <p className="mt-3 text-14">Three steps for creating—and delivering—messaging that sells.</p>
              <Button
                external={true}
                link="/blog/attract-and-convert-more-b2b-customers-with-the-right-brand-story/"
                className="mt-4 text-blue-light"
              >
                Learn more
              </Button>
            </div>
          </div>
          <div>
            <div className="w-10/12 mx-auto xl:ml-auto xl:w-4/5">
              <h4 className="font-bold text-20">Is your B2B brand ready for a refresh?</h4>
              <p className="mt-3 text-14">Seven really good reasons to rebrand.</p>
              <Button
                external={true}
                link="/blog/is-your-b2b-brand-ready-for-a-refresh/"
                className="mt-4 text-blue-light"
              >
                Learn more
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function EmployerBranding() {
  const data = useStaticQuery(query)
  const { employerBrandingImage } = data

  return (
    <div className="grid-cols-10 xl:grid">
      <div className="relative col-span-4">
        <div className="relative sm:breakout xl:mx-0">
          <div
            className="absolute inset-0 hidden bg-blue-light xl:block"
            style={{ clipPath: "polygon(10% 0, 100% 10%, 90% 100%, 0 90%)" }}
          ></div>
          <div className="absolute inset-0 hidden sm:block xl:hidden">
            <div
              className="w-10/12 h-full ml-auto extend bg-blue-light after:bg-blue-light"
              style={{ clipPath: "polygon(5% 0, 100% 10%, 100% 100%, 0 90%)" }}
            ></div>
          </div>
          <div className="absolute inset-0 sm:hidden">
            <div
              className="h-full extend bg-blue-light before:bg-blue-light after:bg-blue-light"
              style={{
                clipPath: "polygon(5% 0, 100% 10%, 100% 100%, 0 90%)",
                width: "150%",
                marginLeft: "-25%",
              }}
            ></div>
          </div>
          <div className="relative flex items-center min-h-550px">
            <div className="grid-cols-2 gap-2 sm:grid xl:gap-0 xl:block">
              <div className="flex flex-col xl:block">
                <div className="pr-12 sm:ml-auto xl:w-3/4 xl:mx-auto xl:pr-0">
                  <h3 className="font-bold leading-8 uppercase font-mongoose text-clamp-35-50">Employer branding.</h3>
                </div>
                <div className="block grid-cols-8 mt-8 xl:grid">
                  <div className="col-span-7 pr-5 xl:pr-0">
                    <GatsbyImage
                      objectFit="contain"
                      loading="eager"
                      image={getImage(employerBrandingImage)}
                      alt="employer branding"
                    />
                  </div>
                </div>
              </div>
              <div className="block mx-auto mt-8 sm:hidden xl:block xl:w-3/4 xl:max-w-full">
                <p className="px-4 text-16 sm:pl-0 sm:pr-7 xl:pr-0">
                  A strong employer brand is critical for engaging your company’s most valuable resource: your people.
                  Here are some of the insights we shared about building, sharing, and measuring the effectiveness of
                  this powerful talent engagement tool.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 hidden sm:block xl:hidden">
          <div className="container flex items-center h-full">
            <div>
              <p className="w-1/2 pl-4 ml-auto text-16 pr-7">
                A strong employer brand is critical for engaging your company’s most valuable resource: your people.
                Here are some of the insights we shared about building, sharing, and measuring the effectiveness of this
                powerful talent engagement tool.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-6 mt-6 xl:mt-0">
        <div className="grid sm:grid-cols-2 sm:gap-x-11 xl:gap-x-0 gap-y-8">
          <div>
            <div className="w-10/12 mx-auto sm:ml-auto sm:mr-0 xl:w-4/5">
              <h4 className="font-bold text-20">A guide for gaining buy-in.</h4>
              <p className="mt-3 text-14">
                A free guide to help your leaders understand the value of an employer brand.
              </p>
              <Button external={true} link="/employer-branding/" className="mt-4 text-blue-light">
                Learn more
              </Button>
            </div>
          </div>
          <div>
            <div className="w-10/12 mx-auto xl:ml-auto xl:w-4/5">
              <h4 className="font-bold text-20">Going beyond your EVP.</h4>
              <p className="mt-3 text-14">An on-demand webinar on how to tell your most compelling talent story.</p>
              <Button
                external={true}
                link="/webinar/beyond-evp-how-to-tell-your-most-compelling-talent-story/"
                className="mt-4 text-blue-light"
              >
                Learn more
              </Button>
            </div>
          </div>
          <div>
            <div className="w-10/12 mx-auto sm:ml-auto sm:mr-0 xl:w-4/5">
              <h4 className="font-bold text-20">Can I “fake it &apos;til I make it” when crafting my EVP?</h4>
              <p className="mt-3 text-14">
                Tips for including work-in-progress employee experience initiatives in your EVP—the right way.
              </p>
              <Button
                external={true}
                link="/blog/can-i-fake-it-til-i-make-it-when-crafting-my-evp/"
                className="mt-4 text-blue-light"
              >
                Learn more
              </Button>
            </div>
          </div>
          <div>
            <div className="w-10/12 mx-auto xl:ml-auto xl:w-4/5">
              <h4 className="font-bold text-20">Connecting more powerfully with talent.</h4>
              <p className="mt-3 text-14">
                JK’s CEO, Martha Marchesi, shared her expertise on the importance of developing an employer brand and
                how leaders can more effectively retain and motivate talent on the “A Seat at the Table” podcast.
              </p>
              <Button
                external={true}
                link="https://podcasts.apple.com/us/podcast/why-winning-companies-tell-their-brand-story-to-their/id1528336384?i=1000588796857"
                className="mt-4 text-blue-light"
              >
                Learn more
              </Button>
            </div>
          </div>
          <div>
            <div className="w-10/12 mx-auto sm:ml-auto sm:mr-0 xl:w-4/5">
              <h4 className="font-bold text-20">Corporate vs. employer branding.</h4>
              <p className="mt-3 text-14">
                Why you need an employer brand—even if you already have a strong corporate brand.
              </p>
              <Button
                external={true}
                link="/blog/corporate-branding-vs-employer-branding-one-size-does-not-fit-all/"
                className="mt-4 text-blue-light"
              >
                Learn more
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function NewInsights() {
  const [tab, setTab] = useState(0)

  return (
    <section>
      <div className="container">
        <div className="relative pt-8 pb-16 bg-white-dark">
          <div className="flex items-center gap-3 ml-9">
            <button
              className={`${
                tab === 0 ? "font-bold underline border-teal-light bg-teal-light" : "border-teal-light hover:underline"
              } px-4 py-2  border-2 rounded-lg text-16 `}
              onClick={() => {
                setTab(0)
              }}
            >
              B2B BRANDING
            </button>
            <button
              className={`${
                tab === 0 ? "border-blue-light hover:underline" : "font-bold underline border-blue-light bg-blue-light"
              } px-4 py-2 border-2 rounded-lg text-16`}
              onClick={() => setTab(1)}
            >
              EMPLOYER BRANDING
            </button>
          </div>
          <div className="text-center mt-14 xl:mt-0">
            <h2 className="font-bold leading-8 uppercase font-mongoose text-clamp-40-70">New Insights.</h2>
          </div>
          <div className="relative z-10 mx-auto mt-6 lg:mt-20 xl:w-10/12">
            {tab === 0 ? <B2bBranding /> : <EmployerBranding />}
          </div>
          <div className="absolute inset-0 flex overflow-hidden pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 1360 796" className="mt-auto">
              <defs>
                <clipPath id="otb-clip-path">
                  <rect
                    id="Rectangle_1745"
                    data-name="Rectangle 1745"
                    width="1360"
                    height="796"
                    transform="translate(40 3305)"
                    fill="#fff8f4"
                  />
                </clipPath>
              </defs>
              <g
                id="Mask_Group_23"
                data-name="Mask Group 23"
                transform="translate(-40 -3305)"
                clipPath="url(#otb-clip-path)"
              >
                <text
                  id="OUTSIDE_THE_BOX"
                  data-name="OUTSIDE THE BOX"
                  transform="translate(720 4119)"
                  fill="#fff"
                  fontSize="380"
                  fontFamily="Mongoose-Bold, Mongoose"
                  fontWeight="700"
                >
                  <tspan x="-702.24" y="0">
                    OUTSIDE THE BOX
                  </tspan>
                </text>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </section>
  )
}

const query = graphql`
  query {
    employerBrandingImage: file(relativePath: { eq: "year-in-review/employer-branding.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    b2bBrandingImage: file(relativePath: { eq: "year-in-review/b2b-branding.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
