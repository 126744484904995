import React from "react"
import { YearInReviewLayout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { usePageSetup } from "@components/usePageSetup"
import { Arrow } from "@components/UI"
import { Hero, NewInsights, Achieve, Opportunities, Success, SupportComm, NewThings } from "@components/YearInReview"

const YearInReviewPage = () => {
  usePageSetup({ navigationColor: "text-white-dark" })

  return (
    <YearInReviewLayout>
      <Seo title="Year in Review" description="" />

      <Hero />

      <NewThings />

      <Opportunities />

      <Success />

      <NewInsights />

      <SupportComm />

      <Achieve />

      <Arrow colorTop="bg-transparent" colorBottom="bg-white-dark" fillColor="text-white-dark" />

      <div className="container pt-clamp-25-41">
        <div className="mx-auto xl:w-10/12">
          <p className="font-semi text-teal-light">
            Contact us today and get a free “BrandExam” from JK’s branding specialists! We’ll take a look at your
            current corporate or employer brand and identify three things you can do to make a bigger impact.
          </p>
        </div>
      </div>
    </YearInReviewLayout>
  )
}

export default YearInReviewPage
