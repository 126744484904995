import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Button } from "@components/Button"
import { Carousel } from "@components/Carousel"

function SuccessCard({ children, title, number = "01", bgColor = "bg-purple-light" }) {
  return (
    <div
      className={`${number === "02" ? "lg:border-l lg:border-white" : ""} grid grid-cols-8`}
      style={{ gridTemplateRows: "min-content 1fr" }}
    >
      <div
        className={`relative z-10 col-span-7 col-start-1 row-start-2 row-end-3 sm:col-span-5  ${
          number === "02" ? "sm:col-start-2 lg:col-start-3" : "sm:col-start-2"
        }`}
      >
        {children}
      </div>
      <div
        className={`${number === "02" && "col-start-1 lg:col-start-2"} col-span-1 row-start-1 row-end-2 ml-6 sm:ml-0`}
      >
        <span className="font-bold text-white font-mongoose text-42 sm:text-60">{number}</span>
      </div>
      <div
        className={`${
          number === "02" ? "col-start-2 lg:col-start-3" : "col-start-2"
        } flex items-center col-span-7 row-start-1 row-end-2 ml-6 sm:ml-0`}
      >
        <span className="font-bold text-white text-clamp-18-26">{title}</span>
      </div>
      <div
        className={`col-span-8 col-start-1 row-start-2 row-end-3 sm:col-span-6 ${
          number === "02" ? "sm:col-start-2 lg:col-start-3" : "sm:col-start-2"
        }  -left-4 sm:left-0`}
      >
        <div
          className={`mt-12 ml-10 ${bgColor} aspect-w-400 aspect-h-351 -left-5 sm:left-0`}
          style={{
            clipPath: "polygon(1% 1%, 100% 0%, 99% 99%, 0 100%)",
            transform: "rotate(5deg)",
            transformOrigin: "top left",
            minHeight: "350px",
          }}
        ></div>
      </div>
    </div>
  )
}

export function Success() {
  const data = useStaticQuery(query)

  const { wbImage, bpmImage } = data

  return (
    <section className="pt-clamp-12-14 pb-clamp-12-17">
      <div className="sm:container">
        <div className="mx-auto xl:w-10/12">
          <div className="text-center">
            <h2 className="font-bold leading-8 uppercase font-mongoose text-clamp-40-70 text-teal-light">
              New successes.
            </h2>
          </div>
          <div className="w-11/12 grid-cols-2 gap-4 mt-12 lg:w-8/12 xl:w-full xl:grid">
            <Carousel>
              <Carousel.Swiper
                breakpoints={{
                  1436: {
                    allowTouchMove: false,
                  },
                }}
              >
                <Carousel.Slide>
                  <SuccessCard title="Warner Bros.">
                    <div>
                      <GatsbyImage objectFit="contain" image={getImage(wbImage)} alt="Warner Bros." />
                    </div>
                    <div className="ml-12 sm:ml-16 mt-7" style={{ maxWidth: "280px" }}>
                      <p className="text-16">
                        How does an entertainment legend capture new fans? Tell stories that captivate.
                      </p>
                      <Button external={true} className="mt-4" link="/work/warner-bros">
                        Learn more
                      </Button>
                    </div>
                  </SuccessCard>
                </Carousel.Slide>
                <Carousel.Slide>
                  <SuccessCard title="BPM" bgColor="bg-blue-light" number="02">
                    <div>
                      <GatsbyImage objectFit="contain" image={getImage(bpmImage)} alt="BPM" />
                    </div>
                    <div className="ml-16 mt-7" style={{ width: "280px" }}>
                      <p className="text-16">
                        How does a CPA firm connect with people that matter? Out with the old, in with the bold.
                      </p>
                      <Button external={true} className="mt-4" link="/work/bpm">
                        Learn more
                      </Button>
                    </div>
                  </SuccessCard>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
          <div className="flex justify-center mt-20 sm:mt-24">
            <Button external={true} className="px-5 py-4 rounded-xl bg-teal-light" link="/work">
              Explore our projects
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

const query = graphql`
  query {
    wbImage: file(relativePath: { eq: "year-in-review/wb.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmImage: file(relativePath: { eq: "year-in-review/bpm.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
